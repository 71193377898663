export const config = {
  homepage: "https://www.ouellet.dev",
  // Your Github Converted Token To Know How To Get Your Token Look at Readme.md
  githubConvertedToken:
    "ZTM3ZmFlNGRlYjU5OTVjYjM5YTdhMjEwNDgzZjQwYzM4ZGU4YTBlOA==",

  // Your Github UserName
  githubUserName: "CircArgs",

  // Your Github Repo Name Where You Have your issues as Blog
  githubRepo: "circargs.github.io",
  resumeRepo: "resume",
  resumeHtml: "master:index.html", //branch:filePath
  resumeCss: "master:resume.css", //branch:filePath
  resumePdfUrl: "https://github.com/CircArgshttps://www.linkedin.com/in/nicholas-ouellet//raw/mainhttps://www.linkedin.com/in/nicholas-ouellet/.pdf",
  // Set it to true if you have a Github app to add to this project
  // and fill the client ID & secret
  enableOAuth: true,
  OAuthClientID: "Iv1.e1236ccdb2bc0ea7",
  OAuthSecret: "6c62bf42e77be7bb24f37bafe658023c2ebffb3a",
};
