import React from "react";

export default (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      viewBox="0 0 475.000000 561.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,561.000000) scale(0.100000,-0.100000)"
        fill="#cccfbe"
        stroke="none"
      >
        <path
          d="M2181 5493 c-27 -54 -113 -231 -191 -393 -78 -162 -220 -455 -315
-650 -95 -195 -221 -454 -279 -575 -105 -215 -109 -222 -190 -305 -146 -148
-267 -326 -371 -542 -56 -117 -119 -293 -131 -364 l-6 -41 101 -36 c79 -28
111 -35 144 -31 23 3 309 -20 636 -51 327 -30 595 -54 596 -53 1 2 17 32 36
68 36 69 150 193 188 205 17 5 20 11 14 23 -4 10 -9 340 -11 735 l-2 717 -25
-6 -25 -6 0 241 c0 233 1 242 23 276 14 23 30 35 45 35 l22 0 0 303 c0 285 -1
304 -20 332 -16 24 -20 47 -20 123 l0 92 -85 0 -85 0 -49 -97z m-51 -648 c46
-24 70 -62 70 -112 0 -59 -25 -99 -78 -124 -68 -34 -141 -8 -177 61 -58 113
70 234 185 175z m-249 -500 c90 -47 90 -188 -2 -237 -65 -35 -138 -13 -179 54
-69 114 61 245 181 183z"
        />
        <path
          d="M2990 5498 c0 -77 -4 -97 -20 -118 -18 -24 -20 -43 -20 -332 0 -300
1 -308 20 -308 11 0 30 -16 45 -37 25 -38 25 -38 25 -270 0 -200 -2 -233 -15
-233 -13 0 -15 -90 -17 -717 -2 -395 -7 -725 -11 -735 -6 -12 -3 -18 14 -23
40 -13 153 -136 189 -207 37 -72 43 -75 112 -59 l27 6 -100 157 c-54 86 -99
163 -99 171 0 20 11 28 395 319 498 377 619 468 630 479 6 6 -10 29 -42 62
-45 45 -70 90 -191 342 -211 439 -466 966 -629 1298 l-147 297 -83 0 -83 0 0
-92z m411 -653 c110 -57 78 -227 -47 -251 -43 -8 -113 25 -137 65 -21 37 -22
109 -1 140 40 56 124 77 185 46z m257 -505 c84 -52 79 -176 -10 -230 -112 -68
-244 63 -183 181 34 65 128 89 193 49z"
        />
        <path
          d="M2535 4632 l-60 -7 -3 -907 -2 -908 235 0 235 0 0 904 c0 568 -4 907
-10 911 -14 9 -326 14 -395 7z"
        />
        <path
          d="M4316 4093 c-3 -10 -23 -98 -46 -196 -29 -131 -40 -201 -40 -261 l0
-83 -222 -170 c-123 -93 -297 -226 -388 -295 -208 -156 -383 -289 -393 -298
-7 -6 125 -223 153 -254 8 -8 105 44 715 382 143 80 347 192 453 250 l192 105
0 236 -1 236 -43 180 -42 180 -166 3 c-152 2 -167 1 -172 -15z m88 -75 c-4
-35 -10 -83 -13 -108 l-6 -45 -49 -3 c-37 -2 -47 0 -44 10 3 7 16 57 28 111
l22 97 35 0 34 0 -7 -62z m130 -5 c4 -38 10 -87 13 -110 l6 -43 -70 0 -69 0
12 98 c7 53 13 103 14 110 0 8 16 12 44 12 l43 0 7 -67z m115 -30 c13 -54 26
-104 28 -110 4 -9 -9 -13 -46 -13 -46 0 -51 2 -52 23 0 12 -6 61 -13 110 l-12
87 36 0 36 0 23 -97z"
        />
        <path
          d="M4395 3003 c-82 -46 -296 -165 -475 -263 -408 -223 -480 -264 -476
-267 2 -2 223 17 492 43 269 25 508 43 532 40 33 -5 66 2 147 30 l103 36 -25
91 c-27 99 -90 266 -126 333 l-22 42 -150 -85z"
        />
        <path
          d="M2576 2729 c-73 -18 -174 -80 -230 -140 -100 -108 -142 -228 -133
-379 8 -149 74 -270 194 -362 82 -62 157 -89 263 -95 155 -10 279 37 386 143
240 239 173 645 -133 796 -82 41 -87 42 -196 44 -62 2 -129 -1 -151 -7z m177
-150 c44 -12 102 -60 124 -103 20 -39 20 -123 0 -161 -35 -68 -96 -105 -172
-105 -97 0 -170 61 -189 159 -14 78 48 177 129 206 49 17 60 18 108 4z"
        />
        <path
          d="M843 2462 l-103 -26 0 -193 0 -192 117 -37 117 -36 565 61 c311 34
576 64 587 66 20 4 21 9 13 42 -11 49 -11 147 0 195 9 37 8 38 -23 42 -44 7
-1138 106 -1156 105 -8 -1 -61 -13 -117 -27z"
        />
        <path
          d="M3820 2435 c-305 -29 -555 -54 -555 -54 0 -1 0 -63 1 -138 l1 -138
59 -7 c32 -3 294 -32 582 -63 l523 -57 120 38 119 38 -2 190 -3 191 -100 27
c-55 14 -120 26 -145 26 -25 0 -295 -24 -600 -53z"
        />
        <path
          d="M1990 2020 c-497 -58 -1021 -109 -1049 -104 -24 5 -59 -4 -144 -36
-61 -24 -116 -47 -121 -51 -13 -12 27 -199 70 -329 40 -120 124 -301 191 -410
64 -103 202 -286 256 -338 l44 -43 13 94 12 95 306 264 c169 144 403 346 521
448 117 102 219 189 226 194 10 7 0 24 -37 69 -27 33 -62 84 -76 114 -16 32
-32 52 -42 52 -8 -1 -85 -10 -170 -19z"
        />
        <path
          d="M3202 1977 c-17 -35 -55 -88 -83 -118 l-50 -54 43 -39 c24 -21 111
-96 193 -166 83 -71 225 -193 316 -271 91 -79 240 -208 331 -287 l165 -142 12
-78 c7 -42 14 -90 17 -106 l6 -28 50 48 c175 171 377 506 462 765 44 134 83
316 70 328 -5 5 -61 28 -123 51 -83 30 -124 40 -146 36 -17 -4 -245 16 -506
44 -261 28 -517 55 -569 60 -52 6 -108 12 -125 15 -28 5 -32 1 -63 -58z"
        />
        <path
          d="M2365 1760 c-6 -9 -156 -140 -605 -525 -69 -59 -188 -162 -264 -229
-77 -66 -145 -125 -152 -131 -8 -6 -22 -69 -35 -148 -17 -114 -18 -141 -8
-153 25 -30 214 -166 307 -221 275 -162 559 -267 830 -308 129 -20 396 -19
530 1 275 41 553 143 824 303 84 49 220 144 275 192 l30 25 -22 149 c-21 141
-24 151 -52 175 -17 14 -155 133 -308 265 -153 132 -364 314 -469 404 -105 90
-201 173 -213 184 l-23 20 -67 -31 c-88 -40 -140 -51 -238 -52 -95 0 -172 18
-255 60 -65 33 -76 35 -85 20z m76 -163 c148 -79 102 -301 -65 -315 -68 -5
-119 18 -153 70 -58 86 -32 198 57 245 55 29 105 29 161 0z m685 4 c59 -27 87
-72 92 -146 3 -50 0 -66 -20 -95 -36 -53 -83 -80 -140 -80 -56 0 -80 10 -125
53 -64 60 -68 157 -10 224 56 63 126 79 203 44z m-353 -75 c72 -30 117 -121
97 -196 -21 -79 -86 -130 -165 -130 -55 0 -93 18 -129 60 -55 66 -54 155 1
221 47 56 127 74 196 45z m-507 -307 c60 -28 89 -78 89 -150 0 -103 -78 -175
-180 -167 -96 8 -155 73 -155 171 0 127 129 203 246 146z m1032 -12 c52 -34
75 -85 70 -152 -5 -63 -35 -108 -88 -135 -85 -43 -189 -10 -229 73 -27 56 -27
103 2 157 47 89 159 115 245 57z m-525 -91 c72 -30 117 -121 97 -196 -21 -79
-86 -130 -165 -130 -55 0 -93 18 -129 60 -55 66 -54 155 1 221 47 56 127 74
196 45z"
        />
      </g>
    </svg>
  );
};
