import React from "react";

export default (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      viewBox="0 0 980.000000 978.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,978.000000) scale(0.100000,-0.100000)"
        fill="#b8a89a"
        stroke="none"
      >
        <path
          d="M2602 4920 c-321 -45 -653 -166 -892 -326 -479 -320 -796 -792 -906
-1348 -14 -72 -28 -182 -31 -244 -7 -131 -8 -129 100 -199 489 -319 1829 -488
2872 -362 281 34 521 85 735 156 183 61 426 186 448 230 9 18 -1 269 -11 279
-3 3 -37 -19 -76 -50 -38 -31 -75 -56 -81 -56 -6 0 -11 12 -10 28 1 15 2 80 1
145 l-1 117 -65 0 -65 0 0 -95 0 -95 -35 0 c-28 0 -35 4 -35 20 0 19 -7 20
-134 20 l-135 0 -3 41 -3 41 -85 2 c-47 2 -91 4 -97 4 -8 1 -13 16 -13 37 0
28 4 35 20 35 18 0 20 7 20 55 l0 55 210 0 210 0 0 45 0 45 -275 0 -275 0 0
40 c0 22 -4 40 -10 40 -5 0 -10 20 -10 45 l0 45 53 0 c30 0 56 2 58 4 2 2 2
29 1 60 l-4 56 -49 0 -49 0 0 50 0 49 133 3 132 3 0 45 0 45 -182 3 -183 2 0
50 0 50 -35 0 -35 0 0 50 c0 47 2 50 25 50 24 0 25 2 25 65 l0 65 75 0 75 0 0
35 0 35 -100 0 -100 0 0 25 c0 23 -4 25 -45 25 l-45 0 0 45 0 45 -70 0 -69 0
-3 78 -3 77 -146 15 c-101 11 -153 20 -172 33 -21 13 -48 17 -127 17 l-100 0
0 45 0 45 115 0 115 0 0 25 c0 26 1 26 -95 46 -66 13 -482 20 -563 9z m-384
-552 c223 -74 372 -301 372 -569 0 -307 -176 -603 -453 -763 -176 -102 -403
-113 -577 -29 -75 36 -188 150 -229 232 -89 175 -95 395 -17 602 39 105 83
179 156 267 131 157 296 251 500 286 51 9 184 -5 248 -26z"
        />
        <path
          d="M1955 4309 c-232 -43 -428 -203 -540 -442 -147 -310 -66 -660 181
-785 317 -160 735 72 874 486 79 237 33 484 -118 624 -69 63 -129 94 -221 114
-74 15 -104 16 -176 3z m55 -564 c46 -45 61 -77 62 -134 0 -17 -16 -39 -54
-72 -51 -46 -56 -48 -113 -49 -59 0 -62 1 -105 45 -43 44 -45 48 -45 105 0 55
3 62 40 100 50 52 69 61 124 56 36 -3 51 -12 91 -51z"
        />
        <path
          d="M772 2706 c11 -194 71 -465 137 -618 l20 -48 81 0 81 0 44 -96 c25
-54 45 -111 45 -130 l0 -34 70 0 70 0 0 65 0 65 180 0 180 0 0 75 0 75 95 0
c57 0 95 -4 95 -10 0 -6 37 -10 90 -10 l90 0 0 -55 0 -55 -90 0 -90 0 0 -70 0
-69 -32 -6 c-18 -3 -55 -4 -83 -3 -50 3 -50 3 -53 -29 l-3 -33 88 0 88 0 26
-50 c15 -27 25 -51 22 -53 -2 -3 -64 -2 -138 1 l-135 5 0 -32 0 -31 -155 0
c-148 0 -155 1 -155 20 0 11 -6 20 -12 21 -49 3 -148 4 -148 1 0 -12 141 -170
221 -247 109 -105 170 -155 292 -237 257 -173 573 -292 882 -333 116 -15 415
-21 474 -9 30 6 31 9 31 55 l0 48 -52 19 c-29 11 -52 23 -50 26 1 3 2 12 2 19
0 7 25 25 55 40 55 27 56 27 280 27 l225 0 0 65 0 65 -60 0 -60 0 0 45 0 45
80 0 c47 0 80 4 80 10 0 6 25 10 55 10 l55 0 0 40 0 40 -145 0 -145 0 0 45 0
45 270 0 270 0 0 35 0 34 123 3 122 3 3 48 3 47 -135 0 -136 0 0 25 0 25 -186
0 -185 0 3 38 3 37 393 3 392 2 0 55 0 55 -205 0 -205 0 0 80 0 80 320 0 320
0 0 25 c0 24 -3 25 -60 25 l-61 0 3 147 3 147 75 48 c41 26 78 47 83 47 4 1 7
-42 7 -94 l0 -95 60 0 60 0 0 109 0 110 32 3 c24 2 34 9 36 23 2 11 28 43 59
71 l56 52 5 78 5 78 -29 -19 c-301 -197 -766 -321 -1419 -376 -183 -16 -799
-16 -990 0 -400 33 -720 81 -1015 152 -260 63 -544 176 -665 264 -16 12 -35
25 -40 28 -7 3 -8 -33 -3 -107z m1888 -931 l0 -85 -115 0 -115 0 0 -25 0 -25
-127 0 c-71 0 -165 3 -210 7 l-83 6 0 43 0 42 53 -1 c52 -2 52 -2 55 31 l3 32
140 0 c139 0 139 0 139 23 0 13 3 27 7 30 3 4 62 7 130 7 l123 0 0 -85z"
        />
      </g>
    </svg>
  );
};
