import {Anchor, Box, Button, Heading, Paragraph} from "grommet";
import {Linkedin, MailOption, Phone} from "grommet-icons";
import React, {useEffect, useRef, useState} from "react";
import Typewriter from "typewriter-effect";

const Contact = (props) => {
  const [initialHeader, setInitialHeader] = useState(true);
  const [inView, setInView] = useState();
  const [showInfo, setshowInfo] = useState(false);

  const ref = useRef();
  useEffect(() => {
    if (ref.current && !inView) {
      let rect = ref.current.getBoundingClientRect();
      setInView(
        window.innerHeight -
        ((rect.top * 2) / 3 + rect.bottom / 3) -
        props.footerHeight >
        0
      );
    }
  }, [props.scrolling]);

  return (
    <Box
      ref={ref}
      id="contact"
      pad={{ horizontal: "10rem", vertical: "2.5rem" }}
      width="100vw"
      direction="row"
      justify="center"
      align="start"
      wrap
      height={{ min: props.height + "px" }}
      border={{
        color: "brand",
        size: "medium",
        style: "solid",
        side: "top",
      }}
      style={{ visibility: inView ? "visible" : "hidden" }}
      animation={inView ? { type: "fadeIn", delay: 500, duration: 1500 } : null}
    >
      <>
        {inView ? (
          <Box justify="center" align="center">
            <>
              {initialHeader ? (
                <Heading textAlign="center">
                  <Typewriter
                    onInit={(typewriter) => {
                      typewriter
                        .changeDelay(70)
                        .pauseFor(150)
                        .typeString("Questions?")
                        .pauseFor(150)
                        .deleteAll()
                        .typeString("Comments?")
                        .pauseFor(150)
                        .deleteAll()
                        .typeString("Opportunities?")
                        .pauseFor(150)
                        .deleteAll()
                        .typeString("Get in Touch.")
                        .pauseFor(150)
                        .callFunction(() => setInitialHeader(false))
                        .start();
                    }}
                  />
                </Heading>
              ) : (
                <Heading>Get in Touch.</Heading>
              )}
            </>
            {!showInfo ? <Button margin="large" primary label="Show Contact Info" onClick={() => setshowInfo(true)} /> :
              <Box
                margin="large"
                align="center"
                animation={{ type: "fadeIn", duration: 1000 }}
              >
                <Box margin="small" direction="row">
                  <Anchor
                    a11yTitle="Reach me on LinkedIn"
                    href="https://www.linkedin.com/in/nicholas-ouellet"
              icon={<Linkedin color="brand" />}
                  label="https://www.linkedin.com/in/nicholas-ouellet"
                    />

                  </Box>
                  <Box margin="small" direction="row">
                  <MailOption size="medium" />
                  <Paragraph margin={{ horizontal: "small" }}>

                    <Anchor href="mailto:Nick@Ouellet.dev">
                      Nick@Ouellet.dev
                    </Anchor>
                  </Paragraph>
                </Box>
              </Box>}
          </Box>
        ) : null}
      </>
    </Box>
  );
};

export default Contact;
