import React from "react";

export default (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      viewBox="0 0 807.000000 1624.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,1624.000000) scale(0.100000,-0.100000)"
        fill="#ba0022"
        stroke="none"
      >
        <path
          d="M3540 15943 c0 -5 -18 -265 -40 -579 -21 -314 -48 -721 -60 -905 -80
-1312 -182 -2357 -301 -3089 -98 -601 -220 -1025 -320 -1119 -18 -17 -22 -50
-45 -422 l-26 -404 53 -55 c204 -213 191 -390 -36 -511 -33 -18 -61 -33 -62
-33 -1 -1 -9 -70 -18 -154 l-16 -153 25 -64 c112 -278 46 -468 -205 -590 -47
-23 -122 -50 -166 -60 l-80 -18 -37 -75 -38 -76 54 -46 c29 -26 66 -58 83 -73
l30 -25 -79 -36 c-43 -20 -82 -36 -87 -36 -5 0 -18 -19 -30 -42 -116 -228
-285 -456 -483 -654 -159 -158 -274 -251 -412 -331 l-84 -48 0 -75 c0 -108 5
-120 47 -120 61 0 155 -29 258 -80 229 -114 476 -332 767 -676 83 -98 285
-365 314 -414 l18 -30 113 0 113 0 0 63 c0 225 72 644 152 882 154 462 424
763 744 830 53 11 75 11 127 0 136 -28 277 -112 398 -236 270 -275 432 -741
480 -1384 l13 -165 106 0 106 0 65 92 c101 142 271 352 403 497 334 367 638
575 889 606 l57 7 0 98 c0 90 -2 99 -20 105 -11 4 -66 37 -123 74 -309 204
-578 505 -789 883 -35 64 -67 110 -79 113 -11 4 -49 20 -86 37 l-66 30 54 46
c29 26 67 58 83 73 l28 25 -34 75 -35 75 -85 21 c-178 44 -324 143 -383 258
-52 103 -47 239 15 387 l28 67 -13 143 c-8 79 -15 149 -15 155 0 7 -27 26 -60
43 -74 38 -124 80 -156 137 -23 38 -26 52 -22 116 3 62 8 82 38 131 19 31 62
87 95 124 l61 67 -24 399 -23 399 -31 36 c-34 38 -67 107 -105 216 -198 576
-366 1938 -473 3860 -27 490 -88 1474 -96 1553 l-6 62 -74 0 -74 0 0 -620 0
-620 -23 0 -24 0 -6 -1012 c-4 -557 -7 -1246 -7 -1530 l0 -518 -41 0 -42 0 8
1530 7 1530 -25 0 -25 0 6 620 5 620 -77 0 c-42 0 -76 -3 -76 -7z"
        />
        <path
          d="M2569 8382 c-142 -92 -215 -247 -189 -403 8 -44 17 -69 25 -69 19 0
125 81 158 120 84 99 114 254 72 363 -9 23 -13 22 -66 -11z"
        />
        <path
          d="M4864 8354 c-20 -138 19 -278 101 -360 54 -54 128 -99 150 -91 18 7
27 112 15 180 -20 126 -92 229 -199 286 l-60 31 -7 -46z"
        />
        <path
          d="M899 7464 l-163 -209 -350 -251 -351 -250 1 -43 c1 -31 7 -49 26 -68
l26 -26 368 145 367 144 60 -24 c86 -34 179 -46 262 -33 38 5 72 14 76 18 4 4
5 33 1 63 l-7 55 -48 9 c-74 13 -53 31 41 34 138 5 176 -37 117 -133 -40 -65
-31 -157 30 -307 10 -25 32 -22 49 5 14 20 13 29 -4 86 -23 73 -26 130 -7 155
12 16 16 14 53 -30 21 -26 42 -52 45 -57 4 -5 24 -6 46 -2 37 6 56 24 219 206
99 110 187 219 198 241 l19 42 -39 33 c-123 104 -324 167 -457 143 l-59 -11
-68 65 c-37 36 -76 66 -87 66 -10 0 -38 -14 -63 -31 -53 -36 -135 -79 -150
-79 -21 0 -9 38 30 99 l40 61 -29 46 -28 46 -164 -208z"
        />
        <path
          d="M6437 7601 c-15 -22 -27 -45 -27 -50 0 -6 18 -38 40 -72 22 -34 40
-68 40 -76 0 -29 -51 -10 -152 58 -31 22 -67 39 -78 39 -13 0 -49 -26 -89 -65
l-67 -64 -40 10 c-126 35 -354 -31 -486 -141 l-31 -26 21 -44 c11 -25 97 -133
191 -240 143 -163 178 -197 212 -209 l41 -13 31 38 c18 21 40 47 49 59 43 52
56 -52 20 -158 -19 -57 -19 -58 0 -82 11 -14 23 -25 27 -25 11 0 44 79 66 154
21 71 16 137 -14 177 -13 17 -21 43 -21 67 0 51 24 62 135 62 67 0 85 -3 85
-14 0 -10 -15 -17 -44 -21 l-44 -7 -7 -55 c-4 -30 -3 -59 2 -64 14 -14 146
-30 198 -24 28 3 84 18 126 32 l75 25 362 -147 c199 -81 369 -144 378 -141 20
8 57 92 49 114 -3 9 -162 131 -354 271 l-348 253 -146 192 c-80 105 -152 199
-160 209 -12 18 -15 16 -40 -22z"
        />
        <path
          d="M3623 6616 c-94 -33 -144 -61 -209 -118 -46 -40 -258 -286 -303 -351
-86 -125 -163 -380 -210 -697 -24 -159 -51 -439 -51 -524 0 -44 -17 -120 -59
-268 l-58 -208 34 0 c26 0 49 11 85 40 35 27 60 40 82 40 29 0 37 -10 150
-188 66 -103 142 -221 168 -262 l48 -76 -6 -76 -7 -75 -48 -29 c-27 -15 -49
-32 -49 -36 0 -4 14 -29 30 -55 41 -64 78 -184 91 -296 l12 -92 116 116 c137
136 225 189 316 189 92 0 179 -52 318 -190 115 -116 117 -117 117 -85 0 96 53
284 100 359 17 26 30 50 30 54 0 4 -22 21 -49 36 l-49 29 -7 73 c-5 52 -3 79
7 97 7 13 83 132 168 265 148 231 156 242 186 242 22 0 47 -13 82 -40 36 -29
59 -40 84 -40 l35 0 -59 207 c-39 138 -58 226 -58 263 -1 240 -73 725 -141
945 -65 210 -94 259 -267 462 -128 148 -180 199 -248 239 -51 30 -212 85 -218
75 -3 -4 -2 -150 1 -324 l6 -316 49 -6 c27 -4 81 -23 120 -42 280 -140 345
-502 132 -732 -145 -157 -364 -194 -556 -94 -154 80 -248 237 -248 414 0 217
174 424 381 454 l46 7 5 316 c3 175 2 320 -1 324 -3 3 -47 -8 -98 -26z"
        />
        <path
          d="M3638 5931 c-80 -26 -139 -63 -188 -118 -220 -247 -81 -630 246 -683
121 -20 261 27 350 118 220 223 126 590 -174 682 -79 25 -159 25 -234 1z m249
-167 c93 -57 144 -164 129 -266 -22 -144 -174 -251 -313 -220 -155 35 -249
197 -198 341 42 120 140 185 268 177 53 -3 78 -10 114 -32z"
        />
        <path
          d="M3675 5702 c-51 -24 -92 -72 -101 -118 -24 -127 53 -226 176 -226 99
0 180 83 180 185 0 47 -38 115 -80 143 -51 34 -123 40 -175 16z"
        />
        <path
          d="M2415 4968 c-55 -39 -166 -152 -202 -207 -41 -61 -82 -171 -83 -218
0 -30 -3 -32 -43 -38 -62 -9 -69 -16 -65 -61 3 -38 4 -39 66 -57 60 -18 63
-21 58 -46 -3 -14 -8 -56 -12 -93 -7 -74 0 -69 -108 -84 l-48 -7 6 -71 c3 -39
9 -98 12 -131 6 -60 6 -60 -25 -72 -26 -10 -31 -16 -31 -46 0 -47 36 -161 56
-178 23 -19 13 -94 -41 -304 -38 -150 -171 -607 -195 -671 -6 -15 -4 -37 6
-67 17 -49 116 -178 191 -248 l49 -46 -25 -37 -24 -37 22 -37 c79 -132 132
-272 103 -272 -25 0 -121 80 -244 203 l-139 139 -34 -39 c-18 -21 -39 -48 -46
-60 -11 -20 45 -110 616 -1005 345 -541 633 -989 639 -996 9 -9 39 -12 102
-10 l90 3 -214 490 c-201 458 -214 491 -203 520 20 57 13 119 -29 235 -31 89
-60 217 -60 271 0 5 11 9 25 9 28 0 26 -5 54 153 10 59 15 110 10 113 -5 3
-31 12 -59 20 -27 8 -50 17 -50 21 0 3 9 36 20 73 19 65 22 68 56 75 l36 6 -6
132 c-8 155 -15 217 -26 217 -18 0 -105 -67 -116 -88 l-11 -23 -7 23 c-3 13
-6 40 -6 60 0 26 -7 44 -22 58 -22 20 -23 27 -20 168 3 157 2 152 60 192 20
14 21 19 12 90 -7 50 -7 110 0 180 30 290 139 608 346 1010 l96 185 -12 53
c-13 59 -5 60 -92 -11 l-46 -39 -69 7 c-37 4 -73 10 -79 14 -7 4 11 77 54 214
l64 209 -28 29 c-27 28 -31 29 -124 29 l-96 0 -34 60 c-19 33 -36 60 -39 60
-3 0 -19 -10 -36 -22z"
        />
        <path
          d="M5022 4930 l-33 -60 -102 -1 c-100 0 -102 -1 -124 -29 l-23 -28 64
-208 c35 -115 62 -210 61 -211 -1 -2 -36 -8 -79 -13 l-77 -11 -60 52 c-67 58
-74 57 -84 -16 -5 -36 4 -60 98 -245 216 -428 328 -783 331 -1055 0 -60 -2
-128 -6 -150 -7 -38 -5 -41 34 -68 l41 -29 -7 -105 c-4 -71 -2 -119 6 -146 11
-38 11 -40 -15 -59 -20 -14 -27 -28 -27 -53 0 -18 -3 -47 -6 -64 l-7 -32 -18
30 c-23 39 -108 89 -119 71 -6 -10 -20 -188 -25 -325 0 -5 16 -14 36 -19 35
-9 38 -12 56 -80 l20 -71 -55 -18 c-31 -9 -58 -19 -60 -22 -2 -2 6 -63 18
-134 22 -127 24 -131 48 -131 25 0 25 0 17 -72 -3 -40 -18 -104 -32 -143 -67
-186 -73 -223 -52 -294 10 -35 3 -53 -205 -523 -119 -267 -216 -489 -216 -493
0 -14 180 0 194 16 7 8 298 454 645 992 l633 978 -43 55 c-23 30 -45 56 -48
58 -4 3 -63 -52 -131 -121 -125 -126 -230 -213 -256 -213 -33 0 -6 77 78 223
l50 88 -26 33 -25 33 85 87 c101 104 154 180 161 233 3 28 -9 84 -47 208 -115
378 -190 677 -190 754 0 22 9 55 19 73 20 34 51 137 51 171 0 10 -14 26 -31
35 l-31 17 7 95 c3 52 9 111 12 132 l5 36 -64 7 c-36 4 -70 10 -76 14 -9 6
-37 181 -29 187 1 1 29 10 62 20 60 19 60 19 63 61 l3 41 -55 12 c-52 11 -56
13 -56 40 0 89 -78 239 -173 334 -50 50 -133 116 -146 116 -3 0 -21 -27 -39
-60z"
        />
        <path
          d="M2948 4249 c-185 -341 -302 -640 -353 -900 -20 -101 -31 -311 -19
-356 l9 -32 78 92 c260 308 393 593 413 886 9 123 -27 317 -69 375 -13 17 -17
12 -59 -65z"
        />
        <path
          d="M4476 4258 c-104 -295 -31 -643 212 -1003 44 -66 113 -158 153 -204
90 -105 94 -101 86 93 -10 293 -114 623 -320 1020 -45 88 -88 161 -94 163 -6
2 -22 -29 -37 -69z"
        />
        <path
          d="M3672 3535 c-71 -22 -110 -62 -204 -204 -88 -133 -90 -137 -86 -187
4 -51 63 -191 95 -226 12 -13 32 -18 75 -18 l58 0 0 -205 0 -205 145 0 145 0
0 205 0 204 63 3 62 3 37 75 c42 84 64 166 54 203 -11 44 -183 291 -220 315
-39 27 -117 52 -155 51 -14 0 -45 -6 -69 -14z"
        />
      </g>
    </svg>
  );
};
