import React, { useState, useEffect, useRef } from "react";

import { Heading, Box, List } from "grommet";

const Interests = (props) => {
  const [inView, setInView] = useState();

  const ref = useRef();
  useEffect(() => {
    if (ref.current && !inView) {
      let rect = ref.current.getBoundingClientRect();
      setInView(
        window.innerHeight -
        ((rect.top * 2) / 3 + rect.bottom / 3) -
        props.footerHeight >
        0
      );
    }
  }, [props.scrolling]);

  return (
    <div>
      <Box
        ref={ref}
        id="hobbies"
        pad={{ horizontal: "10rem", vertical: "2.5rem" }}
        width="100vw"
        // direction="row"
        justify="space-around"
        align="center"
        wrap
        height={{ min: props.height + "px" }}
        border={{
          color: "brand",
          size: "medium",
          style: "solid",
          side: "top",
        }}
        style={{ visibility: inView ? "visible" : "hidden" }}
        animation={
          inView ? { type: "fadeIn", delay: 500, duration: 1500 } : null
        }
      >
        <Heading margin="large" >Interests and Hobbies</Heading>

        <List
          primaryKey="key"
          secondaryKey="value"
          data={[
            {
              key: "Coding",
              value:
                "Of course, making models, developing webapps and exploring nascent tech is of huge interest to me.",
            },
            {
              key: "Blacksmithing",
              value:
                "With a large propane forge my father and I made, I ocassionally hit hot metal with a hammer or weld stuff together.",
            },
            {
              key: "Woodworking",
              value:
                "I've made mostly functional things out of wood from tables to doors in my house.",
            },
            {
              key: "Other DIY Repairs",
              value:
                "Whether changing all of my house's panneling, housewrap and siding on 30ft homemade staging or changing oilpans, fixing more than code becomes neccesity.",
            },
          ]}
        />
      </Box>

    </div>
  );
};

export default Interests;
