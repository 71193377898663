import React from "react";

export default (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      viewBox="0 0 756.000000 279.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,279.000000) scale(0.100000,-0.100000)"
        fill="#79aac7"
        stroke="none"
      >
        <path
          d="M1476 2473 c15 -21 57 -74 93 -118 l65 -80 -55 -8 c-30 -5 -92 -17
-138 -28 -96 -23 -86 -27 -191 70 -57 53 -62 60 -40 61 17 0 21 3 14 10 -6 6
-44 10 -88 8 l-78 -3 93 -109 92 -109 -42 -35 c-25 -20 -40 -40 -37 -48 3 -9
-1 -14 -11 -14 -45 0 22 -25 237 -91 129 -39 251 -81 270 -94 19 -13 68 -38
107 -55 l72 -32 -46 -55 c-46 -54 -47 -54 -62 -34 -18 23 -204 74 -251 69 -14
-1 -43 7 -65 18 -22 11 -145 47 -273 79 -189 47 -235 55 -247 45 -8 -7 -15
-18 -15 -25 0 -7 -17 -16 -37 -20 -33 -6 -47 -19 -105 -96 -60 -78 -68 -93
-68 -132 0 -28 -6 -49 -16 -57 -10 -9 -14 -26 -12 -49 4 -42 -8 -37 317 -132
183 -53 206 -57 221 -44 23 21 30 19 30 -8 0 -21 10 -26 78 -47 42 -13 79 -25
81 -27 2 -1 -23 -30 -56 -64 -55 -56 -62 -61 -91 -54 -27 5 -32 11 -32 35 0
16 -4 31 -9 34 -5 3 -59 22 -121 41 -75 24 -117 32 -128 26 -11 -6 -20 -3 -31
11 -24 29 -467 163 -487 147 -8 -6 -14 -17 -14 -24 0 -8 -17 -16 -39 -20 -33
-5 -47 -18 -110 -95 -50 -62 -71 -96 -71 -116 0 -15 -10 -38 -21 -50 -19 -21
-20 -26 -9 -54 7 -17 17 -33 22 -36 5 -3 105 -39 223 -79 187 -65 215 -72 226
-59 11 14 14 12 25 -9 10 -18 36 -32 104 -55 86 -29 116 -33 477 -64 380 -32
385 -32 399 -13 14 19 14 19 12 -4 -3 -24 6 -27 346 -118 370 -100 370 -100
380 -47 6 31 38 108 52 124 19 23 21 51 4 61 -8 4 -145 45 -305 90 -159 45
-296 84 -302 88 -7 4 62 30 155 58 l167 51 89 -14 c177 -26 753 -67 761 -53 3
3 42 1 89 -6 l85 -11 23 53 c13 29 26 53 31 53 4 0 -4 -22 -17 -49 -26 -50
-22 -71 14 -71 9 0 207 -45 441 -100 392 -92 425 -98 437 -83 7 10 10 23 8 30
-6 15 22 89 51 132 31 45 29 58 -9 70 -691 216 -811 252 -825 247 -6 -3 -21
-23 -33 -46 -11 -22 -22 -40 -25 -40 -6 0 16 51 26 64 17 19 -12 44 -63 56
-29 6 -71 16 -93 22 l-40 10 95 35 c115 42 173 52 304 54 87 2 126 -4 281 -40
99 -23 342 -90 540 -148 198 -58 373 -108 389 -111 26 -4 27 -3 17 17 -7 11
-8 21 -3 21 8 0 773 -161 1212 -255 91 -19 244 -51 340 -71 96 -20 178 -38
182 -40 3 -2 8 2 11 8 5 16 284 -37 308 -59 14 -12 300 -83 336 -83 9 0 9 8 1
31 -8 23 -25 38 -74 62 -35 17 -64 35 -64 38 0 4 14 18 32 30 l32 23 76 -27
c42 -15 79 -27 83 -27 17 0 5 40 -15 52 -63 34 -158 77 -153 68 10 -17 -20
-11 -76 14 -43 20 -49 25 -29 26 23 1 22 3 -13 21 -61 32 -457 205 -676 295
-285 118 -668 260 -921 342 -280 91 -268 88 -327 58 -55 -27 -153 -102 -153
-116 0 -5 24 -15 53 -23 95 -27 381 -107 462 -129 75 -20 85 -26 160 -98 l80
-77 215 -63 c118 -34 225 -66 238 -71 12 -5 37 -31 54 -59 17 -27 38 -51 47
-54 521 -139 627 -173 609 -191 -3 -4 -77 12 -164 35 -88 23 -287 75 -444 116
-157 40 -316 82 -355 93 -38 10 -133 35 -210 55 -77 20 -303 79 -503 131 -199
52 -367 95 -372 95 -15 0 -12 -54 5 -76 16 -22 20 -54 5 -54 -5 0 -14 19 -20
43 -6 23 -15 50 -20 59 -6 10 -10 31 -10 48 0 25 4 30 25 30 14 0 25 6 25 14
0 7 16 45 35 84 42 83 133 174 212 212 28 13 50 27 47 31 -11 19 -566 189
-966 298 -332 90 -544 138 -625 140 -12 1 -35 13 -52 27 -26 21 -64 32 -233
66 -180 35 -205 43 -225 65 -20 24 -41 29 -348 80 -180 29 -330 52 -332 49 -3
-2 -9 -22 -14 -43 -10 -45 4 -89 32 -98 53 -17 -1 -24 -202 -25 -121 0 -224
-2 -228 -3 -9 -3 -221 194 -221 205 0 4 7 8 16 8 8 0 12 5 9 10 -3 6 -40 10
-82 10 l-75 0 28 -37z m1066 -259 c-54 -27 -85 -63 -100 -117 -12 -42 -12 -42
48 -52 115 -17 523 -97 795 -155 165 -36 478 -117 598 -155 l112 -36 88 -99
c48 -55 81 -97 75 -95 -7 2 -114 29 -238 59 -124 30 -443 109 -710 175 -267
65 -552 136 -635 156 -82 20 -156 43 -164 51 -9 8 -16 42 -19 94 -4 73 -2 83
21 119 30 45 112 91 151 84 24 -5 22 -7 -22 -29z m488 -920 c36 -8 82 -19 102
-25 l38 -10 -30 -59 c-17 -33 -37 -60 -45 -60 -8 0 -47 9 -87 19 -40 11 -85
22 -101 25 l-28 7 27 47 c32 56 44 72 53 72 3 0 35 -7 71 -16z m902 -275 c65
-17 118 -36 118 -42 0 -6 -7 -31 -16 -54 -19 -48 -27 -50 -95 -29 -24 8 -76
24 -116 35 l-71 21 21 50 c11 28 25 50 31 50 5 0 63 -14 128 -31z m-1676 -243
l82 -21 -20 -52 c-11 -32 -25 -53 -34 -53 -13 0 -195 49 -201 54 -1 1 9 26 22
55 22 46 27 52 47 45 13 -4 60 -17 104 -28z"
        />
        <path
          d="M3696 1742 c-2 -4 -7 -24 -11 -44 -7 -37 -7 -37 37 -48 23 -6 49 -13
55 -15 16 -5 43 33 43 60 1 18 2 18 15 -5 8 -14 14 -35 15 -47 0 -13 5 -23 10
-23 6 0 10 6 10 14 0 8 14 25 32 37 36 26 21 40 -16 15 -23 -14 -24 -14 -30 7
-4 18 -19 26 -70 40 -72 19 -83 20 -90 9z"
        />
        <path
          d="M4765 1496 c-32 -48 -57 -118 -45 -126 8 -5 754 -213 840 -234 43
-10 -157 209 -203 222 -18 5 -59 17 -92 26 -33 9 -156 43 -273 75 l-213 58
-14 -21z"
        />
      </g>
    </svg>
  );
};
