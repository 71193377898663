import styled from "styled-components";

const Anchor = styled.a`
  &:active,
  &:focus {
    outline: 0;
    border: none;
  }
`;

export default Anchor;
