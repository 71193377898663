import styled from "styled-components";
import { Box } from "grommet";
import React from "react";
const HyperSpace = styled(Box)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center;
  position: absolute;
  overflow: hidden;

  .formation_1 {
    width: 1px;
    height: 10px;
    background: transparent;
    box-shadow: 78px 1586px #fff, 857px 219px #fff, 1359px 1898px #fff,
      500px 893px #fff, 522px 1231px #fff, 930px 583px #fff, 93px 1031px #fff,
      539px 373px #fff, 1453px 1601px #fff, 700px 1182px #fff, 835px 1404px #fff,
      669px 52px #fff, 1173px 901px #fff, 1339px 745px #fff, 1193px 445px #fff,
      1314px 563px #fff, 699px 1876px #fff, 1591px 146px #fff, 1315px 331px #fff,
      1743px 867px #fff, 1705px 462px #fff, 1520px 693px #fff, 198px 754px #fff,
      1850px 188px #fff, 519px 1862px #fff, 1235px 1770px #fff,
      143px 1823px #fff, 1453px 1526px #fff, 761px 1440px #fff,
      1958px 1182px #fff, 1444px 855px #fff, 313px 150px #fff, 1235px 8px #fff,
      349px 928px #fff, 720px 1634px #fff, 129px 741px #fff, 1120px 1060px #fff,
      1197px 40px #fff, 1464px 503px #fff, 624px 281px #fff, 402px 1393px #fff,
      1955px 1356px #fff, 398px 405px #fff, 1863px 325px #fff, 1902px 494px #fff,
      1817px 942px #fff, 874px 1338px #fff, 605px 642px #fff, 1719px 1090px #fff,
      756px 1899px #fff, 712px 579px #fff, 1018px 539px #fff, 1885px 1437px #fff,
      876px 420px #fff, 560px 752px #fff, 1689px 1260px #fff, 77px 1468px #fff,
      1936px 738px #fff, 293px 1829px #fff, 1888px 195px #fff, 1870px 788px #fff,
      751px 1059px #fff, 178px 1289px #fff, 806px 1031px #fff, 709px 1551px #fff,
      130px 172px #fff, 1726px 181px #fff, 595px 310px #fff, 1588px 601px #fff,
      1532px 973px #fff, 18px 870px #fff, 1056px 1128px #fff, 1397px 634px #fff,
      1883px 1917px #fff, 957px 581px #fff, 1329px 1561px #fff,
      739px 1640px #fff, 101px 27px #fff, 78px 92px #fff, 1026px 996px #fff,
      664px 1769px #fff, 973px 1598px #fff, 656px 214px #fff, 112px 574px #fff,
      1355px 1659px #fff, 463px 437px #fff, 1440px 53px #fff, 989px 1600px #fff,
      1511px 301px #fff, 560px 51px #fff, 1041px 478px #fff, 1771px 249px #fff,
      459px 513px #fff, 1153px 27px #fff, 512px 1750px #fff, 1306px 1193px #fff,
      449px 1703px #fff, 705px 1180px #fff, 1761px 328px #fff, 1789px 474px #fff,
      1148px 393px #fff, 1373px 324px #fff, 1872px 145px #fff, 1138px 212px #fff,
      58px 1813px #fff, 1336px 1865px #fff, 203px 1710px #fff, 483px 985px #fff,
      1009px 1793px #fff, 1291px 1490px #fff, 958px 91px #fff, 444px 246px #fff,
      899px 853px #fff, 811px 262px #fff, 1743px 306px #fff, 1086px 1749px #fff,
      1277px 666px #fff, 658px 615px #fff, 678px 17px #fff, 1392px 704px #fff,
      400px 803px #fff, 128px 747px #fff, 749px 17px #fff, 79px 1603px #fff,
      626px 1115px #fff, 1832px 1537px #fff, 140px 832px #fff,
      1033px 1924px #fff, 626px 1783px #fff, 701px 833px #fff, 272px 706px #fff,
      1306px 1243px #fff, 1035px 725px #fff, 1130px 1583px #fff,
      433px 118px #fff, 1788px 1118px #fff, 1351px 192px #fff, 620px 1649px #fff,
      475px 1752px #fff, 499px 995px #fff, 1942px 1531px #fff,
      1573px 1845px #fff, 1007px 798px #fff, 1464px 1996px #fff,
      673px 970px #fff, 145px 1514px #fff, 1128px 1211px #fff, 700px 696px #fff,
      1477px 1042px #fff, 69px 529px #fff, 1087px 529px #fff, 1591px 230px #fff,
      528px 15px #fff, 1000px 179px #fff, 1529px 1571px #fff, 398px 957px #fff,
      591px 1665px #fff, 1902px 1812px #fff, 947px 1931px #fff,
      137px 1290px #fff, 1704px 166px #fff, 352px 1368px #fff, 393px 808px #fff,
      1710px 1569px #fff, 649px 1224px #fff, 1808px 1938px #fff,
      956px 1569px #fff, 1668px 782px #fff, 1205px 1888px #fff, 1757px 80px #fff,
      188px 424px #fff, 1120px 87px #fff, 771px 34px #fff, 1319px 1147px #fff,
      18px 485px #fff, 1650px 706px #fff, 1061px 1187px #fff, 738px 815px #fff,
      1838px 1534px #fff, 1743px 571px #fff, 662px 539px #fff,
      1889px 1945px #fff, 1911px 513px #fff, 1142px 1115px #fff,
      1771px 1112px #fff, 867px 24px #fff, 1157px 148px #fff, 1060px 55px #fff,
      746px 358px #fff, 837px 960px #fff, 243px 1074px #fff, 1084px 1181px #fff,
      1732px 1150px #fff, 665px 1046px #fff, 1339px 1112px #fff,
      1825px 1763px #fff, 1395px 504px #fff, 869px 1941px #fff, 268px 464px #fff,
      377px 1005px #fff, 969px 836px #fff, 1342px 1116px #fff, 1608px 600px #fff,
      1959px 568px #fff, 1379px 325px #fff, 273px 944px #fff, 241px 1690px #fff,
      896px 239px #fff, 153px 493px #fff, 1123px 1774px #fff, 1795px 1700px #fff,
      506px 1336px #fff, 1493px 1297px #fff, 9px 536px #fff, 1432px 21px #fff,
      1814px 1240px #fff, 1451px 110px #fff, 429px 1818px #fff,
      1152px 1833px #fff, 1708px 1746px #fff, 745px 1180px #fff,
      185px 1994px #fff, 1192px 1726px #fff, 508px 1665px #fff,
      876px 1109px #fff, 228px 178px #fff, 635px 42px #fff, 200px 1420px #fff,
      400px 896px #fff, 462px 655px #fff, 395px 1342px #fff, 907px 1428px #fff,
      958px 587px #fff, 1723px 691px #fff, 1586px 1334px #fff, 1804px 49px #fff,
      460px 862px #fff, 1536px 1430px #fff, 846px 818px #fff, 1839px 1633px #fff,
      1345px 1294px #fff, 1582px 378px #fff, 71px 57px #fff, 127px 210px #fff,
      942px 1236px #fff, 1911px 621px #fff, 1576px 289px #fff,
      1033px 1572px #fff, 1881px 345px #fff, 851px 1211px #fff, 310px 737px #fff,
      47px 127px #fff, 170px 899px #fff, 977px 89px #fff, 190px 1457px #fff,
      765px 1407px #fff, 1788px 1468px #fff, 814px 82px #fff, 1151px 254px #fff,
      992px 320px #fff, 304px 897px #fff, 1069px 131px #fff, 1423px 561px #fff,
      761px 1786px #fff, 487px 1996px #fff, 1124px 614px #fff, 1135px 725px #fff,
      1734px 626px #fff, 660px 1995px #fff, 1169px 1475px #fff, 46px 442px #fff,
      1262px 1823px #fff, 865px 1416px #fff, 1775px 1763px #fff,
      679px 1455px #fff, 1444px 85px #fff, 1768px 284px #fff, 1855px 680px #fff,
      47px 299px #fff, 1354px 659px #fff, 1810px 231px #fff, 1648px 201px #fff,
      1160px 185px #fff, 23px 1178px #fff, 488px 881px #fff, 1170px 468px #fff,
      1654px 54px #fff, 9px 68px #fff, 1672px 1376px #fff, 1772px 837px #fff,
      488px 1696px #fff, 486px 1156px #fff, 1012px 1681px #fff,
      1541px 1836px #fff, 1710px 1693px #fff, 242px 1433px #fff,
      1570px 1634px #fff, 1942px 64px #fff, 737px 1702px #fff,
      1350px 1869px #fff, 1719px 472px #fff, 1699px 875px #fff,
      1875px 1510px #fff, 1793px 1761px #fff, 376px 1246px #fff,
      257px 1928px #fff, 293px 1936px #fff, 651px 688px #fff, 1297px 716px #fff,
      1124px 1823px #fff, 611px 1949px #fff, 1146px 622px #fff,
      1310px 537px #fff, 96px 1850px #fff, 534px 748px #fff, 672px 1401px #fff,
      1534px 938px #fff, 523px 1989px #fff, 1262px 925px #fff,
      1315px 1449px #fff, 1055px 666px #fff, 613px 89px #fff, 666px 1599px #fff,
      121px 818px #fff, 350px 1468px #fff, 1316px 1210px #fff, 168px 901px #fff,
      1226px 1168px #fff, 992px 1400px #fff, 74px 1415px #fff, 1471px 6px #fff,
      772px 750px #fff, 1606px 1980px #fff, 1988px 697px #fff, 786px 1729px #fff,
      1388px 121px #fff, 1658px 1673px #fff, 724px 1399px #fff, 307px 348px #fff,
      1038px 837px #fff, 1470px 1063px #fff, 762px 528px #fff,
      1618px 1726px #fff, 1502px 577px #fff, 310px 11px #fff, 439px 1225px #fff,
      1202px 1896px #fff, 1262px 1729px #fff, 289px 1374px #fff,
      1936px 11px #fff, 417px 192px #fff, 1122px 1468px #fff, 358px 1851px #fff,
      694px 1856px #fff, 1706px 1518px #fff, 91px 754px #fff, 1406px 477px #fff,
      1050px 1705px #fff, 139px 847px #fff, 1947px 1459px #fff,
      1013px 384px #fff, 1376px 1348px #fff, 188px 1773px #fff, 166px 165px #fff,
      731px 1095px #fff, 1185px 1188px #fff, 151px 1147px #fff, 109px 680px #fff,
      1874px 435px #fff, 1221px 1773px #fff, 1665px 960px #fff,
      1667px 1293px #fff, 659px 1712px #fff, 1350px 955px #fff, 25px 486px #fff,
      513px 1413px #fff, 1671px 1011px #fff, 1615px 761px #fff,
      1932px 1227px #fff, 1357px 1163px #fff, 692px 240px #fff,
      1422px 1347px #fff, 1320px 160px #fff, 1410px 594px #fff,
      1718px 940px #fff, 663px 345px #fff, 420px 545px #fff, 307px 771px #fff,
      629px 1982px #fff, 6px 634px #fff, 477px 41px #fff, 694px 1216px #fff,
      1018px 1753px #fff, 1132px 1909px #fff, 1806px 1759px #fff,
      529px 1551px #fff, 834px 1485px #fff, 821px 1953px #fff,
      1753px 1573px #fff, 755px 617px #fff, 1570px 1668px #fff,
      173px 1045px #fff, 1872px 705px #fff, 820px 946px #fff, 436px 1756px #fff,
      1873px 58px #fff, 50px 101px #fff, 107px 1936px #fff, 1803px 999px #fff,
      529px 220px #fff, 814px 1673px #fff, 1339px 1152px #fff, 310px 1330px #fff,
      1654px 1098px #fff, 1618px 1994px #fff, 1763px 1660px #fff,
      183px 928px #fff, 716px 186px #fff, 1170px 141px #fff, 1934px 1563px #fff,
      963px 714px #fff, 50px 81px #fff, 619px 1986px #fff, 780px 893px #fff,
      881px 1755px #fff, 1378px 205px #fff, 1973px 1686px #fff,
      597px 1067px #fff, 1919px 1971px #fff, 1591px 1375px #fff,
      341px 1754px #fff, 614px 1942px #fff, 302px 509px #fff, 558px 119px #fff,
      1811px 146px #fff, 1990px 948px #fff, 1647px 467px #fff,
      1252px 1403px #fff, 1303px 1448px #fff, 1638px 1134px #fff, 819px 5px #fff,
      1307px 46px #fff, 190px 775px #fff, 1544px 417px #fff, 1799px 797px #fff,
      1867px 1034px #fff, 92px 1020px #fff, 514px 1424px #fff, 920px 931px #fff,
      307px 744px #fff, 1294px 409px #fff, 620px 1166px #fff, 25px 1843px #fff,
      1301px 560px #fff, 186px 291px #fff, 984px 1611px #fff, 1503px 1606px #fff,
      1921px 77px #fff, 1628px 1503px #fff, 506px 128px #fff, 1219px 394px #fff,
      1561px 866px #fff, 240px 1839px #fff, 852px 588px #fff, 681px 597px #fff,
      1916px 96px #fff, 1021px 994px #fff, 1322px 292px #fff, 1593px 253px #fff,
      706px 1259px #fff, 222px 1689px #fff, 660px 1910px #fff, 1068px 315px #fff,
      428px 1548px #fff, 680px 79px #fff, 1051px 1817px #fff, 1414px 30px #fff,
      164px 1863px #fff, 1305px 1158px #fff, 73px 440px #fff, 805px 1649px #fff,
      1047px 434px #fff, 1805px 163px #fff, 144px 1890px #fff, 1644px 166px #fff,
      137px 617px #fff, 1272px 1953px #fff, 699px 1851px #fff, 844px 1414px #fff,
      876px 962px #fff, 1093px 872px #fff, 1927px 711px #fff, 783px 1913px #fff,
      1565px 1875px #fff, 1009px 1095px #fff, 848px 1874px #fff,
      1342px 1224px #fff, 619px 630px #fff, 1477px 261px #fff,
      1401px 1757px #fff, 91px 1645px #fff, 317px 1993px #fff, 837px 775px #fff,
      147px 516px #fff, 1776px 1918px #fff, 1648px 249px #fff, 923px 1987px #fff,
      1782px 1783px #fff, 1563px 1164px #fff, 839px 1991px #fff,
      670px 760px #fff, 1296px 1251px #fff, 218px 1969px #fff, 1470px 607px #fff,
      1815px 305px #fff, 767px 260px #fff, 370px 829px #fff, 1919px 711px #fff,
      1884px 1676px #fff, 1128px 1703px #fff, 1712px 283px #fff,
      81px 1499px #fff, 144px 1850px #fff, 175px 1618px #fff, 347px 1352px #fff,
      1164px 1615px #fff, 1784px 15px #fff, 946px 481px #fff, 627px 1018px #fff,
      540px 579px #fff, 566px 597px #fff, 1040px 426px #fff, 863px 1715px #fff,
      1103px 1033px #fff, 26px 259px #fff, 940px 866px #fff, 47px 1255px #fff,
      745px 619px #fff, 373px 86px #fff, 1489px 1403px #fff, 579px 490px #fff,
      933px 1371px #fff, 1028px 722px #fff, 1384px 1812px #fff,
      1160px 1116px #fff, 1761px 1426px #fff, 1196px 286px #fff,
      1858px 190px #fff, 925px 837px #fff, 12px 1042px #fff, 1606px 1203px #fff,
      1205px 1995px #fff, 274px 1501px #fff, 1528px 1998px #fff,
      1560px 1445px #fff, 961px 1099px #fff, 1251px 706px #fff,
      1481px 1964px #fff, 1082px 1233px #fff, 814px 1384px #fff,
      1450px 1455px #fff, 353px 419px #fff, 619px 1178px #fff, 1385px 835px #fff,
      117px 780px #fff, 1141px 172px #fff, 148px 1413px #fff, 738px 1289px #fff,
      1379px 1310px #fff, 1117px 732px #fff, 1984px 1240px #fff,
      1879px 1000px #fff, 1571px 1207px #fff, 27px 216px #fff, 710px 1300px #fff,
      127px 1386px #fff, 1768px 1749px #fff, 801px 1440px #fff,
      1162px 1392px #fff, 431px 146px #fff, 615px 1236px #fff, 81px 1804px #fff,
      1074px 396px #fff, 620px 741px #fff, 967px 940px #fff, 241px 1895px #fff,
      1380px 1481px #fff, 222px 1263px #fff, 1563px 1354px #fff,
      516px 1528px #fff, 1992px 222px #fff, 181px 1773px #fff,
      1931px 1599px #fff, 748px 587px #fff, 1383px 956px #fff,
      1788px 1961px #fff, 1399px 1260px #fff, 1173px 953px #fff,
      1971px 357px #fff, 325px 1937px #fff, 1065px 920px #fff, 1536px 3px #fff,
      1028px 812px #fff, 904px 1076px #fff, 454px 1235px #fff, 813px 1058px #fff,
      188px 1683px #fff, 836px 244px #fff, 846px 48px #fff, 118px 640px #fff,
      1800px 1212px #fff, 1378px 123px #fff, 82px 1601px #fff,
      1999px 1418px #fff, 1807px 318px #fff, 1764px 1711px #fff,
      1314px 89px #fff, 1727px 534px #fff, 468px 951px #fff, 1518px 1682px #fff,
      811px 1553px #fff, 1248px 566px #fff, 269px 491px #fff, 696px 1725px #fff,
      1074px 921px #fff, 940px 485px #fff, 497px 552px #fff, 1375px 1472px #fff,
      1303px 268px #fff, 1249px 779px #fff, 1058px 980px #fff, 182px 1155px #fff,
      1193px 613px #fff, 1875px 1618px #fff, 1380px 1278px #fff,
      1355px 1987px #fff, 288px 404px #fff, 1437px 1338px #fff,
      1737px 258px #fff, 1824px 669px #fff, 529px 44px #fff, 732px 417px #fff,
      1675px 1205px #fff, 496px 219px #fff, 439px 64px #fff, 851px 460px #fff,
      885px 1277px #fff, 1719px 630px #fff, 1945px 876px #fff, 790px 479px #fff,
      298px 1997px #fff, 771px 1910px #fff, 1986px 1139px #fff, 616px 231px #fff,
      1215px 699px #fff, 1002px 213px #fff, 841px 1215px #fff, 399px 1121px #fff,
      580px 1009px #fff, 1258px 27px #fff, 987px 190px #fff, 1147px 919px #fff,
      372px 865px #fff, 432px 86px #fff, 17px 1338px #fff, 1688px 677px #fff,
      538px 1966px #fff, 1612px 542px #fff, 1753px 1843px #fff,
      1453px 950px #fff, 43px 1840px #fff, 1283px 1374px #fff,
      1532px 1644px #fff, 47px 270px #fff, 1447px 918px #fff, 233px 696px #fff,
      1949px 827px #fff, 1124px 833px #fff, 702px 497px #fff, 492px 1000px #fff,
      1900px 721px #fff, 1997px 458px #fff, 13px 1400px #fff, 1572px 1710px #fff,
      958px 1200px #fff, 1050px 1799px #fff, 1160px 1455px #fff,
      1367px 564px #fff, 1522px 619px #fff, 715px 460px #fff, 1883px 1581px #fff,
      1711px 988px #fff, 767px 1273px #fff, 1402px 368px #fff, 1004px 658px #fff,
      1242px 683px #fff, 932px 168px #fff, 1317px 1998px #fff, 530px 1915px #fff,
      368px 820px #fff;
    animation: animStar 5s linear infinite;
  }
  .formation_1:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 10px;
    background: transparent;
    box-shadow: 78px 1586px #fff, 857px 219px #fff, 1359px 1898px #fff,
      500px 893px #fff, 522px 1231px #fff, 930px 583px #fff, 93px 1031px #fff,
      539px 373px #fff, 1453px 1601px #fff, 700px 1182px #fff, 835px 1404px #fff,
      669px 52px #fff, 1173px 901px #fff, 1339px 745px #fff, 1193px 445px #fff,
      1314px 563px #fff, 699px 1876px #fff, 1591px 146px #fff, 1315px 331px #fff,
      1743px 867px #fff, 1705px 462px #fff, 1520px 693px #fff, 198px 754px #fff,
      1850px 188px #fff, 519px 1862px #fff, 1235px 1770px #fff,
      143px 1823px #fff, 1453px 1526px #fff, 761px 1440px #fff,
      1958px 1182px #fff, 1444px 855px #fff, 313px 150px #fff, 1235px 8px #fff,
      349px 928px #fff, 720px 1634px #fff, 129px 741px #fff, 1120px 1060px #fff,
      1197px 40px #fff, 1464px 503px #fff, 624px 281px #fff, 402px 1393px #fff,
      1955px 1356px #fff, 398px 405px #fff, 1863px 325px #fff, 1902px 494px #fff,
      1817px 942px #fff, 874px 1338px #fff, 605px 642px #fff, 1719px 1090px #fff,
      756px 1899px #fff, 712px 579px #fff, 1018px 539px #fff, 1885px 1437px #fff,
      876px 420px #fff, 560px 752px #fff, 1689px 1260px #fff, 77px 1468px #fff,
      1936px 738px #fff, 293px 1829px #fff, 1888px 195px #fff, 1870px 788px #fff,
      751px 1059px #fff, 178px 1289px #fff, 806px 1031px #fff, 709px 1551px #fff,
      130px 172px #fff, 1726px 181px #fff, 595px 310px #fff, 1588px 601px #fff,
      1532px 973px #fff, 18px 870px #fff, 1056px 1128px #fff, 1397px 634px #fff,
      1883px 1917px #fff, 957px 581px #fff, 1329px 1561px #fff,
      739px 1640px #fff, 101px 27px #fff, 78px 92px #fff, 1026px 996px #fff,
      664px 1769px #fff, 973px 1598px #fff, 656px 214px #fff, 112px 574px #fff,
      1355px 1659px #fff, 463px 437px #fff, 1440px 53px #fff, 989px 1600px #fff,
      1511px 301px #fff, 560px 51px #fff, 1041px 478px #fff, 1771px 249px #fff,
      459px 513px #fff, 1153px 27px #fff, 512px 1750px #fff, 1306px 1193px #fff,
      449px 1703px #fff, 705px 1180px #fff, 1761px 328px #fff, 1789px 474px #fff,
      1148px 393px #fff, 1373px 324px #fff, 1872px 145px #fff, 1138px 212px #fff,
      58px 1813px #fff, 1336px 1865px #fff, 203px 1710px #fff, 483px 985px #fff,
      1009px 1793px #fff, 1291px 1490px #fff, 958px 91px #fff, 444px 246px #fff,
      899px 853px #fff, 811px 262px #fff, 1743px 306px #fff, 1086px 1749px #fff,
      1277px 666px #fff, 658px 615px #fff, 678px 17px #fff, 1392px 704px #fff,
      400px 803px #fff, 128px 747px #fff, 749px 17px #fff, 79px 1603px #fff,
      626px 1115px #fff, 1832px 1537px #fff, 140px 832px #fff,
      1033px 1924px #fff, 626px 1783px #fff, 701px 833px #fff, 272px 706px #fff,
      1306px 1243px #fff, 1035px 725px #fff, 1130px 1583px #fff,
      433px 118px #fff, 1788px 1118px #fff, 1351px 192px #fff, 620px 1649px #fff,
      475px 1752px #fff, 499px 995px #fff, 1942px 1531px #fff,
      1573px 1845px #fff, 1007px 798px #fff, 1464px 1996px #fff,
      673px 970px #fff, 145px 1514px #fff, 1128px 1211px #fff, 700px 696px #fff,
      1477px 1042px #fff, 69px 529px #fff, 1087px 529px #fff, 1591px 230px #fff,
      528px 15px #fff, 1000px 179px #fff, 1529px 1571px #fff, 398px 957px #fff,
      591px 1665px #fff, 1902px 1812px #fff, 947px 1931px #fff,
      137px 1290px #fff, 1704px 166px #fff, 352px 1368px #fff, 393px 808px #fff,
      1710px 1569px #fff, 649px 1224px #fff, 1808px 1938px #fff,
      956px 1569px #fff, 1668px 782px #fff, 1205px 1888px #fff, 1757px 80px #fff,
      188px 424px #fff, 1120px 87px #fff, 771px 34px #fff, 1319px 1147px #fff,
      18px 485px #fff, 1650px 706px #fff, 1061px 1187px #fff, 738px 815px #fff,
      1838px 1534px #fff, 1743px 571px #fff, 662px 539px #fff,
      1889px 1945px #fff, 1911px 513px #fff, 1142px 1115px #fff,
      1771px 1112px #fff, 867px 24px #fff, 1157px 148px #fff, 1060px 55px #fff,
      746px 358px #fff, 837px 960px #fff, 243px 1074px #fff, 1084px 1181px #fff,
      1732px 1150px #fff, 665px 1046px #fff, 1339px 1112px #fff,
      1825px 1763px #fff, 1395px 504px #fff, 869px 1941px #fff, 268px 464px #fff,
      377px 1005px #fff, 969px 836px #fff, 1342px 1116px #fff, 1608px 600px #fff,
      1959px 568px #fff, 1379px 325px #fff, 273px 944px #fff, 241px 1690px #fff,
      896px 239px #fff, 153px 493px #fff, 1123px 1774px #fff, 1795px 1700px #fff,
      506px 1336px #fff, 1493px 1297px #fff, 9px 536px #fff, 1432px 21px #fff,
      1814px 1240px #fff, 1451px 110px #fff, 429px 1818px #fff,
      1152px 1833px #fff, 1708px 1746px #fff, 745px 1180px #fff,
      185px 1994px #fff, 1192px 1726px #fff, 508px 1665px #fff,
      876px 1109px #fff, 228px 178px #fff, 635px 42px #fff, 200px 1420px #fff,
      400px 896px #fff, 462px 655px #fff, 395px 1342px #fff, 907px 1428px #fff,
      958px 587px #fff, 1723px 691px #fff, 1586px 1334px #fff, 1804px 49px #fff,
      460px 862px #fff, 1536px 1430px #fff, 846px 818px #fff, 1839px 1633px #fff,
      1345px 1294px #fff, 1582px 378px #fff, 71px 57px #fff, 127px 210px #fff,
      942px 1236px #fff, 1911px 621px #fff, 1576px 289px #fff,
      1033px 1572px #fff, 1881px 345px #fff, 851px 1211px #fff, 310px 737px #fff,
      47px 127px #fff, 170px 899px #fff, 977px 89px #fff, 190px 1457px #fff,
      765px 1407px #fff, 1788px 1468px #fff, 814px 82px #fff, 1151px 254px #fff,
      992px 320px #fff, 304px 897px #fff, 1069px 131px #fff, 1423px 561px #fff,
      761px 1786px #fff, 487px 1996px #fff, 1124px 614px #fff, 1135px 725px #fff,
      1734px 626px #fff, 660px 1995px #fff, 1169px 1475px #fff, 46px 442px #fff,
      1262px 1823px #fff, 865px 1416px #fff, 1775px 1763px #fff,
      679px 1455px #fff, 1444px 85px #fff, 1768px 284px #fff, 1855px 680px #fff,
      47px 299px #fff, 1354px 659px #fff, 1810px 231px #fff, 1648px 201px #fff,
      1160px 185px #fff, 23px 1178px #fff, 488px 881px #fff, 1170px 468px #fff,
      1654px 54px #fff, 9px 68px #fff, 1672px 1376px #fff, 1772px 837px #fff,
      488px 1696px #fff, 486px 1156px #fff, 1012px 1681px #fff,
      1541px 1836px #fff, 1710px 1693px #fff, 242px 1433px #fff,
      1570px 1634px #fff, 1942px 64px #fff, 737px 1702px #fff,
      1350px 1869px #fff, 1719px 472px #fff, 1699px 875px #fff,
      1875px 1510px #fff, 1793px 1761px #fff, 376px 1246px #fff,
      257px 1928px #fff, 293px 1936px #fff, 651px 688px #fff, 1297px 716px #fff,
      1124px 1823px #fff, 611px 1949px #fff, 1146px 622px #fff,
      1310px 537px #fff, 96px 1850px #fff, 534px 748px #fff, 672px 1401px #fff,
      1534px 938px #fff, 523px 1989px #fff, 1262px 925px #fff,
      1315px 1449px #fff, 1055px 666px #fff, 613px 89px #fff, 666px 1599px #fff,
      121px 818px #fff, 350px 1468px #fff, 1316px 1210px #fff, 168px 901px #fff,
      1226px 1168px #fff, 992px 1400px #fff, 74px 1415px #fff, 1471px 6px #fff,
      772px 750px #fff, 1606px 1980px #fff, 1988px 697px #fff, 786px 1729px #fff,
      1388px 121px #fff, 1658px 1673px #fff, 724px 1399px #fff, 307px 348px #fff,
      1038px 837px #fff, 1470px 1063px #fff, 762px 528px #fff,
      1618px 1726px #fff, 1502px 577px #fff, 310px 11px #fff, 439px 1225px #fff,
      1202px 1896px #fff, 1262px 1729px #fff, 289px 1374px #fff,
      1936px 11px #fff, 417px 192px #fff, 1122px 1468px #fff, 358px 1851px #fff,
      694px 1856px #fff, 1706px 1518px #fff, 91px 754px #fff, 1406px 477px #fff,
      1050px 1705px #fff, 139px 847px #fff, 1947px 1459px #fff,
      1013px 384px #fff, 1376px 1348px #fff, 188px 1773px #fff, 166px 165px #fff,
      731px 1095px #fff, 1185px 1188px #fff, 151px 1147px #fff, 109px 680px #fff,
      1874px 435px #fff, 1221px 1773px #fff, 1665px 960px #fff,
      1667px 1293px #fff, 659px 1712px #fff, 1350px 955px #fff, 25px 486px #fff,
      513px 1413px #fff, 1671px 1011px #fff, 1615px 761px #fff,
      1932px 1227px #fff, 1357px 1163px #fff, 692px 240px #fff,
      1422px 1347px #fff, 1320px 160px #fff, 1410px 594px #fff,
      1718px 940px #fff, 663px 345px #fff, 420px 545px #fff, 307px 771px #fff,
      629px 1982px #fff, 6px 634px #fff, 477px 41px #fff, 694px 1216px #fff,
      1018px 1753px #fff, 1132px 1909px #fff, 1806px 1759px #fff,
      529px 1551px #fff, 834px 1485px #fff, 821px 1953px #fff,
      1753px 1573px #fff, 755px 617px #fff, 1570px 1668px #fff,
      173px 1045px #fff, 1872px 705px #fff, 820px 946px #fff, 436px 1756px #fff,
      1873px 58px #fff, 50px 101px #fff, 107px 1936px #fff, 1803px 999px #fff,
      529px 220px #fff, 814px 1673px #fff, 1339px 1152px #fff, 310px 1330px #fff,
      1654px 1098px #fff, 1618px 1994px #fff, 1763px 1660px #fff,
      183px 928px #fff, 716px 186px #fff, 1170px 141px #fff, 1934px 1563px #fff,
      963px 714px #fff, 50px 81px #fff, 619px 1986px #fff, 780px 893px #fff,
      881px 1755px #fff, 1378px 205px #fff, 1973px 1686px #fff,
      597px 1067px #fff, 1919px 1971px #fff, 1591px 1375px #fff,
      341px 1754px #fff, 614px 1942px #fff, 302px 509px #fff, 558px 119px #fff,
      1811px 146px #fff, 1990px 948px #fff, 1647px 467px #fff,
      1252px 1403px #fff, 1303px 1448px #fff, 1638px 1134px #fff, 819px 5px #fff,
      1307px 46px #fff, 190px 775px #fff, 1544px 417px #fff, 1799px 797px #fff,
      1867px 1034px #fff, 92px 1020px #fff, 514px 1424px #fff, 920px 931px #fff,
      307px 744px #fff, 1294px 409px #fff, 620px 1166px #fff, 25px 1843px #fff,
      1301px 560px #fff, 186px 291px #fff, 984px 1611px #fff, 1503px 1606px #fff,
      1921px 77px #fff, 1628px 1503px #fff, 506px 128px #fff, 1219px 394px #fff,
      1561px 866px #fff, 240px 1839px #fff, 852px 588px #fff, 681px 597px #fff,
      1916px 96px #fff, 1021px 994px #fff, 1322px 292px #fff, 1593px 253px #fff,
      706px 1259px #fff, 222px 1689px #fff, 660px 1910px #fff, 1068px 315px #fff,
      428px 1548px #fff, 680px 79px #fff, 1051px 1817px #fff, 1414px 30px #fff,
      164px 1863px #fff, 1305px 1158px #fff, 73px 440px #fff, 805px 1649px #fff,
      1047px 434px #fff, 1805px 163px #fff, 144px 1890px #fff, 1644px 166px #fff,
      137px 617px #fff, 1272px 1953px #fff, 699px 1851px #fff, 844px 1414px #fff,
      876px 962px #fff, 1093px 872px #fff, 1927px 711px #fff, 783px 1913px #fff,
      1565px 1875px #fff, 1009px 1095px #fff, 848px 1874px #fff,
      1342px 1224px #fff, 619px 630px #fff, 1477px 261px #fff,
      1401px 1757px #fff, 91px 1645px #fff, 317px 1993px #fff, 837px 775px #fff,
      147px 516px #fff, 1776px 1918px #fff, 1648px 249px #fff, 923px 1987px #fff,
      1782px 1783px #fff, 1563px 1164px #fff, 839px 1991px #fff,
      670px 760px #fff, 1296px 1251px #fff, 218px 1969px #fff, 1470px 607px #fff,
      1815px 305px #fff, 767px 260px #fff, 370px 829px #fff, 1919px 711px #fff,
      1884px 1676px #fff, 1128px 1703px #fff, 1712px 283px #fff,
      81px 1499px #fff, 144px 1850px #fff, 175px 1618px #fff, 347px 1352px #fff,
      1164px 1615px #fff, 1784px 15px #fff, 946px 481px #fff, 627px 1018px #fff,
      540px 579px #fff, 566px 597px #fff, 1040px 426px #fff, 863px 1715px #fff,
      1103px 1033px #fff, 26px 259px #fff, 940px 866px #fff, 47px 1255px #fff,
      745px 619px #fff, 373px 86px #fff, 1489px 1403px #fff, 579px 490px #fff,
      933px 1371px #fff, 1028px 722px #fff, 1384px 1812px #fff,
      1160px 1116px #fff, 1761px 1426px #fff, 1196px 286px #fff,
      1858px 190px #fff, 925px 837px #fff, 12px 1042px #fff, 1606px 1203px #fff,
      1205px 1995px #fff, 274px 1501px #fff, 1528px 1998px #fff,
      1560px 1445px #fff, 961px 1099px #fff, 1251px 706px #fff,
      1481px 1964px #fff, 1082px 1233px #fff, 814px 1384px #fff,
      1450px 1455px #fff, 353px 419px #fff, 619px 1178px #fff, 1385px 835px #fff,
      117px 780px #fff, 1141px 172px #fff, 148px 1413px #fff, 738px 1289px #fff,
      1379px 1310px #fff, 1117px 732px #fff, 1984px 1240px #fff,
      1879px 1000px #fff, 1571px 1207px #fff, 27px 216px #fff, 710px 1300px #fff,
      127px 1386px #fff, 1768px 1749px #fff, 801px 1440px #fff,
      1162px 1392px #fff, 431px 146px #fff, 615px 1236px #fff, 81px 1804px #fff,
      1074px 396px #fff, 620px 741px #fff, 967px 940px #fff, 241px 1895px #fff,
      1380px 1481px #fff, 222px 1263px #fff, 1563px 1354px #fff,
      516px 1528px #fff, 1992px 222px #fff, 181px 1773px #fff,
      1931px 1599px #fff, 748px 587px #fff, 1383px 956px #fff,
      1788px 1961px #fff, 1399px 1260px #fff, 1173px 953px #fff,
      1971px 357px #fff, 325px 1937px #fff, 1065px 920px #fff, 1536px 3px #fff,
      1028px 812px #fff, 904px 1076px #fff, 454px 1235px #fff, 813px 1058px #fff,
      188px 1683px #fff, 836px 244px #fff, 846px 48px #fff, 118px 640px #fff,
      1800px 1212px #fff, 1378px 123px #fff, 82px 1601px #fff,
      1999px 1418px #fff, 1807px 318px #fff, 1764px 1711px #fff,
      1314px 89px #fff, 1727px 534px #fff, 468px 951px #fff, 1518px 1682px #fff,
      811px 1553px #fff, 1248px 566px #fff, 269px 491px #fff, 696px 1725px #fff,
      1074px 921px #fff, 940px 485px #fff, 497px 552px #fff, 1375px 1472px #fff,
      1303px 268px #fff, 1249px 779px #fff, 1058px 980px #fff, 182px 1155px #fff,
      1193px 613px #fff, 1875px 1618px #fff, 1380px 1278px #fff,
      1355px 1987px #fff, 288px 404px #fff, 1437px 1338px #fff,
      1737px 258px #fff, 1824px 669px #fff, 529px 44px #fff, 732px 417px #fff,
      1675px 1205px #fff, 496px 219px #fff, 439px 64px #fff, 851px 460px #fff,
      885px 1277px #fff, 1719px 630px #fff, 1945px 876px #fff, 790px 479px #fff,
      298px 1997px #fff, 771px 1910px #fff, 1986px 1139px #fff, 616px 231px #fff,
      1215px 699px #fff, 1002px 213px #fff, 841px 1215px #fff, 399px 1121px #fff,
      580px 1009px #fff, 1258px 27px #fff, 987px 190px #fff, 1147px 919px #fff,
      372px 865px #fff, 432px 86px #fff, 17px 1338px #fff, 1688px 677px #fff,
      538px 1966px #fff, 1612px 542px #fff, 1753px 1843px #fff,
      1453px 950px #fff, 43px 1840px #fff, 1283px 1374px #fff,
      1532px 1644px #fff, 47px 270px #fff, 1447px 918px #fff, 233px 696px #fff,
      1949px 827px #fff, 1124px 833px #fff, 702px 497px #fff, 492px 1000px #fff,
      1900px 721px #fff, 1997px 458px #fff, 13px 1400px #fff, 1572px 1710px #fff,
      958px 1200px #fff, 1050px 1799px #fff, 1160px 1455px #fff,
      1367px 564px #fff, 1522px 619px #fff, 715px 460px #fff, 1883px 1581px #fff,
      1711px 988px #fff, 767px 1273px #fff, 1402px 368px #fff, 1004px 658px #fff,
      1242px 683px #fff, 932px 168px #fff, 1317px 1998px #fff, 530px 1915px #fff,
      368px 820px #fff;
  }

  .formation_2 {
    width: 2px;
    height: 20px;
    background: transparent;
    box-shadow: 1144px 1189px #fff, 1621px 1107px #fff, 1184px 712px #fff,
      281px 745px #fff, 499px 774px #fff, 198px 1147px #fff, 366px 1830px #fff,
      89px 439px #fff, 1560px 667px #fff, 560px 950px #fff, 1752px 896px #fff,
      1492px 397px #fff, 752px 1731px #fff, 1137px 209px #fff, 1545px 561px #fff,
      17px 1936px #fff, 1485px 1175px #fff, 1329px 1349px #fff,
      1834px 1741px #fff, 1300px 1499px #fff, 878px 103px #fff,
      1399px 1376px #fff, 1px 70px #fff, 1712px 1348px #fff, 397px 791px #fff,
      711px 1135px #fff, 1072px 791px #fff, 872px 644px #fff, 1004px 49px #fff,
      280px 1961px #fff, 938px 1566px #fff, 1432px 1471px #fff,
      1348px 1302px #fff, 1537px 1929px #fff, 1202px 686px #fff,
      1954px 1791px #fff, 573px 606px #fff, 1234px 1766px #fff, 389px 786px #fff,
      1231px 1211px #fff, 754px 309px #fff, 1898px 466px #fff, 497px 223px #fff,
      1378px 1093px #fff, 394px 1738px #fff, 1550px 531px #fff,
      1636px 601px #fff, 1388px 457px #fff, 661px 518px #fff, 1904px 85px #fff,
      927px 1425px #fff, 1865px 1078px #fff, 1074px 1725px #fff,
      1405px 1218px #fff, 1681px 1297px #fff, 908px 1560px #fff,
      1720px 243px #fff, 1312px 819px #fff, 1706px 1768px #fff, 1102px 1px #fff,
      1715px 1414px #fff, 197px 1433px #fff, 1928px 830px #fff,
      283px 1148px #fff, 84px 343px #fff, 906px 1912px #fff, 86px 80px #fff,
      311px 1113px #fff, 674px 1861px #fff, 425px 811px #fff, 1195px 1682px #fff,
      1063px 1806px #fff, 391px 282px #fff, 1419px 940px #fff, 526px 1558px #fff,
      394px 1816px #fff, 1166px 1107px #fff, 1423px 842px #fff,
      435px 1904px #fff, 256px 861px #fff, 373px 1048px #fff, 468px 1484px #fff,
      768px 1002px #fff, 489px 1964px #fff, 667px 293px #fff, 1876px 1545px #fff,
      149px 604px #fff, 1300px 1775px #fff, 759px 1694px #fff, 1294px 381px #fff,
      1642px 639px #fff, 69px 891px #fff, 873px 1890px #fff, 693px 168px #fff,
      1011px 1203px #fff, 573px 778px #fff, 86px 744px #fff, 99px 107px #fff,
      763px 245px #fff, 697px 972px #fff, 479px 1955px #fff, 1530px 928px #fff,
      1181px 1991px #fff, 1512px 1053px #fff, 1061px 120px #fff,
      753px 1185px #fff, 250px 787px #fff, 897px 1569px #fff, 284px 1784px #fff,
      1139px 1079px #fff, 779px 1654px #fff, 1166px 144px #fff,
      1124px 1257px #fff, 1241px 126px #fff, 1344px 578px #fff,
      1708px 1842px #fff, 960px 1259px #fff, 4px 1351px #fff, 110px 693px #fff,
      1125px 69px #fff, 153px 657px #fff, 1324px 635px #fff, 448px 1130px #fff,
      1564px 726px #fff, 1560px 1136px #fff, 1761px 496px #fff,
      457px 1846px #fff, 296px 313px #fff, 1222px 549px #fff, 665px 489px #fff,
      1657px 448px #fff, 198px 36px #fff, 1298px 1339px #fff, 712px 158px #fff,
      49px 895px #fff, 1467px 1939px #fff, 470px 1309px #fff, 1031px 862px #fff,
      449px 699px #fff, 183px 1985px #fff, 920px 935px #fff, 1352px 1056px #fff,
      1230px 594px #fff, 366px 727px #fff, 1028px 813px #fff, 1630px 493px #fff,
      608px 790px #fff, 806px 811px #fff, 1862px 1248px #fff, 1231px 1032px #fff,
      1752px 136px #fff, 515px 1137px #fff, 1513px 1701px #fff,
      1283px 479px #fff, 1886px 1490px #fff, 1483px 588px #fff,
      1621px 974px #fff, 1898px 1387px #fff, 1616px 1245px #fff,
      386px 656px #fff, 701px 519px #fff, 1500px 1307px #fff, 1365px 1982px #fff,
      1860px 946px #fff, 189px 1387px #fff, 428px 1411px #fff, 545px 1737px #fff,
      1710px 1594px #fff, 125px 839px #fff, 986px 1704px #fff, 313px 1576px #fff,
      727px 1503px #fff, 53px 1615px #fff, 385px 126px #fff, 1593px 235px #fff,
      1516px 577px #fff, 1756px 1622px #fff, 1259px 1195px #fff,
      1683px 526px #fff, 991px 1082px #fff, 1208px 1265px #fff,
      1871px 844px #fff, 418px 1806px #fff, 680px 1148px #fff, 1104px 819px #fff,
      31px 852px #fff, 1320px 533px #fff, 132px 1835px #fff, 764px 90px #fff,
      1610px 1285px #fff, 1118px 206px #fff, 429px 300px #fff,
      1534px 1176px #fff, 1265px 671px #fff, 224px 660px #fff, 1368px 54px #fff,
      1334px 1263px #fff, 715px 539px #fff, 740px 491px #fff, 1491px 23px #fff;
    animation: animStar 10s linear infinite;
  }
  .formation_2:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 20px;
    background: transparent;
    box-shadow: 1144px 1189px #fff, 1621px 1107px #fff, 1184px 712px #fff,
      281px 745px #fff, 499px 774px #fff, 198px 1147px #fff, 366px 1830px #fff,
      89px 439px #fff, 1560px 667px #fff, 560px 950px #fff, 1752px 896px #fff,
      1492px 397px #fff, 752px 1731px #fff, 1137px 209px #fff, 1545px 561px #fff,
      17px 1936px #fff, 1485px 1175px #fff, 1329px 1349px #fff,
      1834px 1741px #fff, 1300px 1499px #fff, 878px 103px #fff,
      1399px 1376px #fff, 1px 70px #fff, 1712px 1348px #fff, 397px 791px #fff,
      711px 1135px #fff, 1072px 791px #fff, 872px 644px #fff, 1004px 49px #fff,
      280px 1961px #fff, 938px 1566px #fff, 1432px 1471px #fff,
      1348px 1302px #fff, 1537px 1929px #fff, 1202px 686px #fff,
      1954px 1791px #fff, 573px 606px #fff, 1234px 1766px #fff, 389px 786px #fff,
      1231px 1211px #fff, 754px 309px #fff, 1898px 466px #fff, 497px 223px #fff,
      1378px 1093px #fff, 394px 1738px #fff, 1550px 531px #fff,
      1636px 601px #fff, 1388px 457px #fff, 661px 518px #fff, 1904px 85px #fff,
      927px 1425px #fff, 1865px 1078px #fff, 1074px 1725px #fff,
      1405px 1218px #fff, 1681px 1297px #fff, 908px 1560px #fff,
      1720px 243px #fff, 1312px 819px #fff, 1706px 1768px #fff, 1102px 1px #fff,
      1715px 1414px #fff, 197px 1433px #fff, 1928px 830px #fff,
      283px 1148px #fff, 84px 343px #fff, 906px 1912px #fff, 86px 80px #fff,
      311px 1113px #fff, 674px 1861px #fff, 425px 811px #fff, 1195px 1682px #fff,
      1063px 1806px #fff, 391px 282px #fff, 1419px 940px #fff, 526px 1558px #fff,
      394px 1816px #fff, 1166px 1107px #fff, 1423px 842px #fff,
      435px 1904px #fff, 256px 861px #fff, 373px 1048px #fff, 468px 1484px #fff,
      768px 1002px #fff, 489px 1964px #fff, 667px 293px #fff, 1876px 1545px #fff,
      149px 604px #fff, 1300px 1775px #fff, 759px 1694px #fff, 1294px 381px #fff,
      1642px 639px #fff, 69px 891px #fff, 873px 1890px #fff, 693px 168px #fff,
      1011px 1203px #fff, 573px 778px #fff, 86px 744px #fff, 99px 107px #fff,
      763px 245px #fff, 697px 972px #fff, 479px 1955px #fff, 1530px 928px #fff,
      1181px 1991px #fff, 1512px 1053px #fff, 1061px 120px #fff,
      753px 1185px #fff, 250px 787px #fff, 897px 1569px #fff, 284px 1784px #fff,
      1139px 1079px #fff, 779px 1654px #fff, 1166px 144px #fff,
      1124px 1257px #fff, 1241px 126px #fff, 1344px 578px #fff,
      1708px 1842px #fff, 960px 1259px #fff, 4px 1351px #fff, 110px 693px #fff,
      1125px 69px #fff, 153px 657px #fff, 1324px 635px #fff, 448px 1130px #fff,
      1564px 726px #fff, 1560px 1136px #fff, 1761px 496px #fff,
      457px 1846px #fff, 296px 313px #fff, 1222px 549px #fff, 665px 489px #fff,
      1657px 448px #fff, 198px 36px #fff, 1298px 1339px #fff, 712px 158px #fff,
      49px 895px #fff, 1467px 1939px #fff, 470px 1309px #fff, 1031px 862px #fff,
      449px 699px #fff, 183px 1985px #fff, 920px 935px #fff, 1352px 1056px #fff,
      1230px 594px #fff, 366px 727px #fff, 1028px 813px #fff, 1630px 493px #fff,
      608px 790px #fff, 806px 811px #fff, 1862px 1248px #fff, 1231px 1032px #fff,
      1752px 136px #fff, 515px 1137px #fff, 1513px 1701px #fff,
      1283px 479px #fff, 1886px 1490px #fff, 1483px 588px #fff,
      1621px 974px #fff, 1898px 1387px #fff, 1616px 1245px #fff,
      386px 656px #fff, 701px 519px #fff, 1500px 1307px #fff, 1365px 1982px #fff,
      1860px 946px #fff, 189px 1387px #fff, 428px 1411px #fff, 545px 1737px #fff,
      1710px 1594px #fff, 125px 839px #fff, 986px 1704px #fff, 313px 1576px #fff,
      727px 1503px #fff, 53px 1615px #fff, 385px 126px #fff, 1593px 235px #fff,
      1516px 577px #fff, 1756px 1622px #fff, 1259px 1195px #fff,
      1683px 526px #fff, 991px 1082px #fff, 1208px 1265px #fff,
      1871px 844px #fff, 418px 1806px #fff, 680px 1148px #fff, 1104px 819px #fff,
      31px 852px #fff, 1320px 533px #fff, 132px 1835px #fff, 764px 90px #fff,
      1610px 1285px #fff, 1118px 206px #fff, 429px 300px #fff,
      1534px 1176px #fff, 1265px 671px #fff, 224px 660px #fff, 1368px 54px #fff,
      1334px 1263px #fff, 715px 539px #fff, 740px 491px #fff, 1491px 23px #fff;
  }

  .formation_3 {
    width: 3px;
    height: 30px;
    background: transparent;
    box-shadow: 1636px 1582px #fff, 1349px 560px #fff, 1094px 526px #fff,
      876px 691px #fff, 1424px 1801px #fff, 1867px 1100px #fff,
      1445px 1268px #fff, 1170px 1355px #fff, 1096px 457px #fff,
      1641px 937px #fff, 657px 959px #fff, 1540px 1719px #fff,
      1251px 1965px #fff, 800px 1346px #fff, 1527px 857px #fff,
      467px 1521px #fff, 663px 429px #fff, 1089px 718px #fff, 137px 125px #fff,
      1978px 1666px #fff, 1140px 614px #fff, 341px 1313px #fff,
      331px 1738px #fff, 565px 623px #fff, 1561px 1016px #fff, 1676px 91px #fff,
      750px 785px #fff, 1893px 1439px #fff, 106px 317px #fff, 944px 326px #fff,
      1525px 1886px #fff, 1661px 1636px #fff, 1819px 951px #fff,
      1798px 248px #fff, 1809px 472px #fff, 669px 589px #fff, 65px 1753px #fff,
      613px 792px #fff, 979px 452px #fff, 907px 1078px #fff, 299px 1643px #fff,
      1370px 595px #fff, 1003px 550px #fff, 1032px 1591px #fff, 406px 9px #fff,
      1997px 1243px #fff, 1352px 1454px #fff, 600px 781px #fff,
      1199px 1726px #fff, 1289px 991px #fff, 1259px 1895px #fff,
      412px 259px #fff, 1778px 1336px #fff, 294px 24px #fff, 1885px 1010px #fff,
      1679px 1194px #fff, 200px 1462px #fff, 1388px 1778px #fff,
      1242px 960px #fff, 614px 1067px #fff, 524px 1103px #fff, 329px 276px #fff,
      1647px 53px #fff, 310px 970px #fff, 523px 1871px #fff, 615px 1236px #fff,
      689px 1749px #fff, 1353px 906px #fff, 1240px 465px #fff, 694px 1304px #fff,
      1113px 1531px #fff, 682px 1143px #fff, 1653px 40px #fff, 981px 561px #fff,
      1312px 508px #fff, 124px 507px #fff, 812px 1711px #fff, 61px 254px #fff,
      1707px 1658px #fff, 128px 774px #fff, 1132px 575px #fff, 73px 147px #fff,
      66px 1705px #fff, 1687px 229px #fff, 1933px 1944px #fff, 953px 282px #fff,
      1907px 1168px #fff, 995px 750px #fff, 1893px 1900px #fff, 371px 206px #fff,
      713px 1795px #fff, 1259px 1428px #fff, 798px 1347px #fff,
      1204px 1033px #fff, 246px 554px #fff, 1733px 409px #fff, 1084px 737px #fff,
      368px 214px #fff, 1126px 645px #fff, 1304px 1065px #fff;
    animation: animStar 15s linear infinite;
  }
  .formation_3:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 30px;
    background: transparent;
    box-shadow: 1636px 1582px .fff, 1349px 560px .fff, 1094px 526px .fff,
      876px 691px #fff, 1424px 1801px #fff, 1867px 1100px #fff,
      1445px 1268px #fff, 1170px 1355px #fff, 1096px 457px #fff,
      1641px 937px #fff, 657px 959px #fff, 1540px 1719px #fff,
      1251px 1965px #fff, 800px 1346px #fff, 1527px 857px #fff,
      467px 1521px #fff, 663px 429px #fff, 1089px 718px #fff, 137px 125px #fff,
      1978px 1666px #fff, 1140px 614px #fff, 341px 1313px #fff,
      331px 1738px #fff, 565px 623px #fff, 1561px 1016px #fff, 1676px 91px #fff,
      750px 785px #fff, 1893px 1439px #fff, 106px 317px #fff, 944px 326px #fff,
      1525px 1886px #fff, 1661px 1636px #fff, 1819px 951px #fff,
      1798px 248px #fff, 1809px 472px #fff, 669px 589px #fff, 65px 1753px #fff,
      613px 792px #fff, 979px 452px #fff, 907px 1078px #fff, 299px 1643px #fff,
      1370px 595px #fff, 1003px 550px #fff, 1032px 1591px #fff, 406px 9px #fff,
      1997px 1243px #fff, 1352px 1454px #fff, 600px 781px #fff,
      1199px 1726px #fff, 1289px 991px #fff, 1259px 1895px #fff,
      412px 259px #fff, 1778px 1336px #fff, 294px 24px #fff, 1885px 1010px #fff,
      1679px 1194px #fff, 200px 1462px #fff, 1388px 1778px #fff,
      1242px 960px #fff, 614px 1067px #fff, 524px 1103px #fff, 329px 276px #fff,
      1647px 53px #fff, 310px 970px #fff, 523px 1871px #fff, 615px 1236px #fff,
      689px 1749px #fff, 1353px 906px #fff, 1240px 465px #fff, 694px 1304px #fff,
      1113px 1531px #fff, 682px 1143px #fff, 1653px 40px #fff, 981px 561px #fff,
      1312px 508px #fff, 124px 507px #fff, 812px 1711px #fff, 61px 254px #fff,
      1707px 1658px #fff, 128px 774px #fff, 1132px 575px #fff, 73px 147px #fff,
      66px 1705px #fff, 1687px 229px #fff, 1933px 1944px #fff, 953px 282px #fff,
      1907px 1168px #fff, 995px 750px #fff, 1893px 1900px #fff, 371px 206px #fff,
      713px 1795px #fff, 1259px 1428px #fff, 798px 1347px #fff,
      1204px 1033px #fff, 246px 554px #fff, 1733px 409px #fff, 1084px 737px #fff,
      368px 214px #fff, 1126px 645px #fff, 1304px 1065px #fff;
  }

  @keyframes animStar {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-2000px);
    }
  }
`;

export default ({ style, ...props }) => (
  <Box {...props} style={{ position: "relative", ...style }}>
    <HyperSpace fill>
      <div className="formation_1"></div>
      <div className="formation_2"></div>
      <div className="formation_3"></div>
    </HyperSpace>
  </Box>
);
